.react-grid-Main {
    outline: none !important;
    overflow: hidden !important;
}

.react-grid-Container {
    margin-bottom: 40px;
}

.react-grid-Grid {
    border: none !important;
    background: white !important;
    color: @light-grey !important;
    text-align: center !important;
}

.react-grid-Canvas {
    background: white !important;
}

.react-grid-Header {
    background: white !important;
}

.react-grid-HeaderRow {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}

.react-grid-HeaderCell {
    border-right: none !important;
    border-bottom: none !important;
    background: white !important;
    text-align: center;
}

.react-grid-Cell {
    border-right: none !important;
    border-bottom: none !important;
    text-align: center !important;
}

.react-grid-Cell:not(.editing):not(.rdg-child-cell) .react-grid-Cell__value {
    transform: none !important;
    margin-top: -12px;
}

.react-grid-Cell:focus {
    outline: none !important;
}

.react-grid-Row {
    height: 40px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}